


















































































































import { defineComponent } from '@vue/composition-api'
import { mapActions, mapState, mapMutations, mapGetters } from 'vuex'
import TopBar from '@/components/organisms/o-top-bar.vue'
import { TrainingSession } from '@/api/interfaces/trainingsession'
import store from '@/store'
import { QueryParams } from '@/store/api-plateform-utils'
import { usePagination, PaginationOptions } from '@/composables/pagination'
import Pagination from '@/components/base/Pagination.vue'
import { format, parseJSON } from 'date-fns'

export default defineComponent({
  setup (props, ctx) {
    const {
      paginationOptions,
      paginationRequestParams,
      nbPages,
      onSortBy,
      onSortDesc,
      }: PaginationOptions = usePagination(() => {
    return ctx.root.$store.state.trainingSessionList.totalItems
  }) as PaginationOptions

    return {
      paginationOptions,
      paginationRequestParams,
      nbPages,
      onSortBy,
      onSortDesc,
    }
  },
  name: 'TrainingSessionAssociateNeedTrainngList',
  components: {
    TopBar,
    Pagination,
    ListTextFilter: () => import('@/components/base/ListTextFilter.vue'),
    DateFieldFilter: () => import('@/components/base/DateFieldFilter.vue'),
  },
  data () {
    return {
      urlPathRedirection: '',
      filters: {
        trainingLocationFilter: '',
        date: '',
      },
      title: this.$t('training_session.list.title_header'),
      headers: [
        {
          text: this.$t('training_session.list.headers.trainingType'),
          align: 'start',
          value: 'trainingType.name',
          sortable: false,
        },
        {
          text: this.$t('training_session.list.headers.trainingLocation'),
          value: 'trainingLocation.name',
          sortable: false,
        },
        {
          text: this.$t('training_session.list.headers.at'),
          value: 'startAt',
        },
        {
          text: this.$t('training_session.list.headers.goal_number_trainees'),
          value: 'goalNumberTrainees',
          sortable: false,
        },
        {
          text: this.$t('training_session.list.headers.status'),
          value: 'status',
        },
        {
          value: 'associate',
          sortable: false,
        },
      ],
      isSubmiting: false,
    }
  },
  computed: {
    ...mapState('trainingSessionList', {
      associateTrainingSessionList: 'list',
    }),
    ...mapState('needTrainingForm', {
      needTraining: 'needTraining',
    }),
    ...mapState('status', {
      statusList: 'list',
    }),
    ...mapGetters('navigation', {
      getLastRouteNeedTraining: 'getLastRouteNeedTraining',
    }),
    trainingTypeId (): unknown {
      return this.needTraining.trainingType['@id'].replace(
        '/api/training_types/',
        '',
      )
    },
    baseRequest (): QueryParams {
      const request: Record<string, unknown> = {
        trainingType: this.needTraining.trainingType['@id'],
        'trainingLocation.name': this.filters.trainingLocationFilter,
        canAssociateTrainingSession: true,
        date: this.filters.date
          ? format(
            parseJSON(this.filters.date as string) || '',
            'dd-MM-yyyy 00:00:00',
          )
          : '',
        ...this.paginationRequestParams,
      }
      return request as QueryParams
    },
  },
  created () {
    if (!this.needTraining?.id) {
      this.$router.push({ name: 'Trainee List' })
    }
    this.load(this.baseRequest)
  },
  watch: {
    filters: {
      handler () {
        this.paginationOptions.page = 1
        this.load(this.baseRequest)
      },
      deep: true,
    },
    paginationOptions: {
      handler () {
        this.load(this.baseRequest)
      },
      deep: true,
    },
  },
  methods: {
    ...mapActions('trainingSessionList', {
      load: 'load',
    }),
    ...mapActions('user', {
      sendSuccessMessage: 'sendSuccessMessage',
    }),
    ...mapMutations('needTrainingForm', {
      associateTrainingSession: 'associateTrainingSession',
    }),
    associateOneTrainingSessionToNeedTraining (
      trainingSession: TrainingSession,
    ) {
      this.isSubmiting = true

      this.associateTrainingSession(trainingSession)

      this.$router.push(store.getters['navigation/getLastRouteNeedTraining'])
    },
    filterByTrainingLocation (value: string) {
      this.filters.trainingLocationFilter = value
    },
    filterByDate (value: string) {
      this.filters.date = value
    },
  },
  async beforeRouteEnter (to, from, next) {
    if (
      store.getters['needTrainingForm/getAssociatedTrainginSession'] != null
    ) {
      next(store.getters['navigation/getLastRouteNeedTraining'])
    }

    store.commit('navigation/addRoute', to)
    next()
  },
  beforeRouteLeave (to, from, next) {
    if (to.name !== 'TrainingSession Create') {
      store.commit('navigation/removeRoute')
    }
    next()
  },
})

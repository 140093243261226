var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('TopBar',{attrs:{"title":_vm.title}},[_c('template',{slot:"actions"},[_c('v-btn',{staticClass:"mb-0 mr-4 px-4",attrs:{"color":"primary","to":{
          name: 'TrainingSession Create',
          params: { trainingType: _vm.trainingTypeId },
        }}},[_vm._v(" "+_vm._s(_vm.$t('btn.add_training_session'))+" ")]),_c('v-btn',{staticClass:"mb-0 px-4",attrs:{"color":"primary","to":_vm.getLastRouteNeedTraining}},[_vm._v(" "+_vm._s(_vm.$t('btn.return'))+" ")])],1)],2),_c('v-container',{attrs:{"id":"training_session-list","fluid":"","tag":"section"}},[_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('base-material-card',{staticClass:"px-5 pt-3 mb-15",attrs:{"dark":_vm.$vuetify.theme.dark,"inline":"","color":"primary","icon":"mdi-clipboard-text"},scopedSlots:_vm._u([{key:"title",fn:function(){return [_c('v-card-title',{staticClass:"pt-0 pb-2"},[_c('div',[_vm._v(" "+_vm._s(_vm.$t('training_session.list.title'))+" ")])])]},proxy:true}])},[_c('v-data-table',{staticClass:"elevation-1 my-3",attrs:{"headers":_vm.headers,"items":_vm.associateTrainingSessionList,"options":_vm.paginationOptions,"hide-default-footer":""},on:{"update:sort-by":_vm.onSortBy,"update:sort-desc":_vm.onSortDesc},scopedSlots:_vm._u([{key:"header.trainingLocation.name",fn:function(ref){
        var header = ref.header;
return [_c('div',{staticClass:"d-flex"},[_vm._v(" "+_vm._s(header.text)+" "),_c('ListTextFilter',{attrs:{"translation":_vm.$t('training_session.list.filter.training_type'),"value":_vm.filters.trainingLocationFilter},on:{"filter-list":_vm.filterByTrainingLocation}})],1)]}},{key:"header.startAt",fn:function(ref){
        var header = ref.header;
return [_c('div',{staticClass:"d-flex"},[_vm._v(" "+_vm._s(header.text)+" "),_c('DateFieldFilter',{attrs:{"label":_vm.$t('form.date_start'),"value":_vm.filters.date},on:{"day-with-no-hours":_vm.filterByDate}})],1)]}},{key:"item.startAt",fn:function(ref){
        var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("formatDateHour")(item.startAt))+" "),(item.endAt !== null)?_c('span',[_vm._v(" - "+_vm._s(_vm._f("formatHour")(item.endAt))+" ")]):_vm._e()]}},{key:"item.status",fn:function(ref){
        var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.$t('training_session.form.status.' + item.status))+" ")]}},{key:"footer",fn:function(){return [_c('Pagination',{attrs:{"pagination-options":_vm.paginationOptions,"nb-pages":_vm.nbPages},on:{"changePaginationOptions":function($event){_vm.paginationOptions = $event}}})]},proxy:true},{key:"item.associate",fn:function(ref){
        var item = ref.item;
return [_c('div',{staticClass:"text-center ma-2 rounded"},[_c('v-btn',{staticClass:"mb-0 px-4",attrs:{"color":"primary"},on:{"click":function($event){return _vm.associateOneTrainingSessionToNeedTraining(item)}}},[_vm._v(" "+_vm._s(_vm.$t('btn.associate'))+" ")])],1)]}}],null,true)})],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }